import { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { arrangeDirectors } from "../Directors";
import { Get } from "../../shared/services/Fetch";
import { userActiveStruct } from "../Directors/helper";
import { notify } from "../../shared/context/AlertContext";
import { noHeadersResponse } from "../../shared/utils/Global";
import GetAuthContext from "../../shared/context/AuthContext";
import ActivityTimeline from "../../components/atomic/ActivityTimeline";
import DropdownWithSearch from "../../components/atomic/DropdownWithSearch";
import { DropdownWithSearchOption } from "../../components/atomic/DropdownWithSearch/interface";

function LoanTimelineTab({
  pageName,
  data,
  loanDetails,
  fetchToken,
  loanID,
  isLenderPlatform,
  whiteLabelStyle,
}: {
  pageName?: string;
  data: any;
  loanDetails: any;
  fetchToken: any;
  loanID: any;
  isLenderPlatform: boolean;
  whiteLabelStyle?:any;
}) {
  const { userActivityHistory } = data || {};

  const [page, setPage] = useState(1);
  const [loanActivity, setLoanActivity] = useState<any[]>([]);
  const [hasMorePages, setHasMorePages] = useState(true);

  const isDirectorOperation = ["Private Limited", "Partnership"].includes(
    loanDetails?.constitution
  );
  const optionsDetails = isDirectorOperation
    ? arrangeDirectors(loanDetails?.directorDetails) || []
    : loanDetails?.coApplicantDetails ?? [];

  let optionList: DropdownWithSearchOption[] = useMemo(
    () => [
      {
        name: isDirectorOperation
          ? userActiveStruct({
              name: loanDetails?.name,
              active: loanDetails?.active,
            })
          : "Primary Applicant",
        value: loanDetails.userID,
      },
      ...optionsDetails?.map((dir: any, idx: number) => ({
        name: isDirectorOperation
          ? userActiveStruct({
              name: dir?.name,
              active: dir?.active,
            })
          : "Co-Applicant " + (idx + 1),
        value: dir.userID,
      })),
    ],
    [loanDetails]
  );
  const [selectedDirector, setSelectedDirector] = useState(optionList?.[0]);

  useEffect(() => {
    setLoanActivity(userActivityHistory || []);
  }, [userActivityHistory]);

  async function getLoanActivity(page = 1, userID: string) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    let url = isLenderPlatform
      ? `/lender/loanActivity?loanApplicationID=${loanID}&userID=${userID}&page=${page}&limit=8`
      : `/masterDashboard/loanActivity?loanApplicationID=${loanID}&userID=${userID}&page=${page}&limit=8`;
    const response: any = await Get(url, fetchToken(), headers);
    if (response.status) {
      const newActivities = response.data?.userActivityHistory || [];
      if (newActivities.length > 0) {
        setLoanActivity((prevActivities) => [
          ...prevActivities,
          ...newActivities,
        ]);
      } else {
        setHasMorePages(false);
      }
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  useEffect(() => {
    if (page > 1 && hasMorePages) {
      getLoanActivity(page, selectedDirector?.value);
    }
  }, [page, hasMorePages]);

  function onViewMoreClick() {
    setPage((prevPage) => prevPage + 1);
  }

  const onChangeDirector = (value: DropdownWithSearchOption) => {
    setPage(1);
    setLoanActivity([]);
    setHasMorePages(true);
    setSelectedDirector(value);
    getLoanActivity(1, value?.value);
  };

  const placeholderDir = isDirectorOperation
    ? loanDetails?.constitution === "Partnership"
      ? "Partner"
      : "Director"
    : "Co-Applicant";

  return (
    <div className="px-4 overflow-auto">
      <div className="flex justify-between items-center text-base font-medium">
        <h3 className="text-sm text-gray-700 font-medium w-max border-b-2 border-indigo-500 py-2 pr-2">
          Timeline
        </h3>
        {optionList?.length > 1 ? (
          <DropdownWithSearch
            hasSearch={false}
            options={optionList}
            selectedItems={selectedDirector}
            placeholder={`Select ${placeholderDir}`}
            onChange={(value: DropdownWithSearchOption) =>
              onChangeDirector(value)
            }
          />
        ) : null}
      </div>
      <div className="min-h-[50vh]">
        <ActivityTimeline
          page={page}
          whiteLabelStyle={whiteLabelStyle}
          timeline={loanActivity}
          hasMorePages={hasMorePages}
          onViewMoreClick={onViewMoreClick}
        />
      </div>
    </div>
  );
}

export default LoanTimelineTab;
