import { Get } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import GetAuthContext from "../../shared/context/AuthContext";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";
import LoanTimelineTab from "../../../unified-dashboard/src/pages/LoanTimeline";

export async function loanTimelineLoader({
  getToken,
  params,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/loanActivity?loanApplicationID=${loanID}&page=1&limit=8`,
    getToken(),
    headers
  );
}

function LoanTimeline({ pageName }: { pageName?: string }) {
  const { loanID } = useParams();
  const { data }: any = useLoaderData();
  const { fetchToken } = GetAuthContext();
  const { loanDetails } = useOutletContext() as any;

  return (
    <LoanTimelineTab
      pageName={pageName}
      isLenderPlatform={false}
      data={data}
      loanDetails={loanDetails}
      fetchToken={fetchToken}
      loanID={loanID}
    />
  );
}

export default LoanTimeline;
